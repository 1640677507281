<template>
    <v-expansion-panel-content>
        <v-row class="pa-4" justify='space-between'>
            <div>
                <div class="sub-title-1">Phase Start & End Dates</div>
                <v-date-picker class="mt-3" 
                    range 
                    no-title 
                    show-adjacent-months
                    :disabled="!isLeader"
                    v-model="phaseDate">
                </v-date-picker>
                <div  class="mt-3">
                    <span v-if="phaseDate && phaseDate.length > 0">From: {{phaseDate[0] | formatDate}}</span>
                    <span class="ml-2" v-if="phaseDate && phaseDate.length > 1">To: {{phaseDate[1] | formatDate}}</span>
                </div>
            </div>
            <div style="width: 65%">
                <v-textarea
                    :disabled="!isLeader"
                    filled
                    rows="8"
                    label="Phase Description / Objectives (optional)"
                    persistent-hint  
                    hint="Enter a brief synopsis of the objectives for this phase (if applicable)" 
                    v-model="phaseDescription"></v-textarea>

                <div class="d-flex justify-space-between">
                    <v-btn
                        v-if='fileName && fileName.length > 0 && uploadedFileName === null'
                        color="primary"
                        outlined
                        class="mt-5"
                        @click='downloadDocument'
                    >
                        Download Document
                    </v-btn>
                    <v-btn
                        v-if='fileName && fileName.length > 0 && isLeader && uploadedFileName === null'
                        color="error"
                        outlined
                        class="mt-5 ml-2"
                        @click='deleteDocument'
                    >
                        Delete Document
                    </v-btn>
                    <v-file-input
                        v-if="isLeader"
                        class="mt-4 ml-2"
                        :rules="rules"
                        v-model="phaseFiles"
                        counter
                        :label="uploadText"
                        :loading="isUploading"
                        multiple
                        placeholder="Select your files"
                        :show-size="1000"
                    >
                    </v-file-input>
                </div>
            </div>
        </v-row>

        <v-row justify='end'>
            <v-btn color="primary" class='ma-4' @click="updatePhase()" :disabled='phaseButtonDisabled'>
                Update Phase Details
            </v-btn>
        </v-row>

    </v-expansion-panel-content>
</template>

<script>
    import {  fetchProjectPhase, saveProjectPhase, saveProjectPhaseDocument, deleteProjectPhaseDocument, downloadProjectPhaseDocument, fetchProjectPhaseDocument } from '../../api/course';
    import moment from 'moment';

    const MAX_SIZE =  30 * 1024 * 1024

    export default {
        props: {
            phaseNumber: { type: Number, default: null },
            isLeader: { type: Boolean, default: false }
        },
        data: () => {
            return {
                phaseDate: null,
                phaseDescription: null,
                fileName: null,
                phaseExists: false,
                phaseApproved: false,
                phaseFiles: null,
                uploadedFileName: null,
                rules: [
                    phaseFiles => !phaseFiles || !phaseFiles.some(phaseFiles => phaseFiles.size > MAX_SIZE) || 'Project size should be less than 30 MB!'
                ],
                isUploading: false
            };
        },
        mounted() {
            this.fetchPhaseData()
        },
        methods:{
            async updatePhase(){
                const params = {
                    phaseDescription: this.phaseDescription,
                    fileName: this.uploadedFileName || this.fileName,
                    startDate: this.phaseDate[0],
                    endDate: this.phaseDate[1],
                    phaseNumber: this.phaseNumber
                }

                try{
                    await saveProjectPhase(params)   
                    this.$toast.open({
                        message: `Project phase ${this.phaseNumber} updated.`,
                        type: 'success',
                    });
                    this.fetchPhaseData()
                }
                catch(e){
                    if(e.code === 'ERR_BAD_REQUEST'){
                        this.$toast.open({
                        message: e.response.data.details || e.response.data.error.message,
                        type: 'error',
                        });
                    }
                    else{
                        this.$toast.open({
                        message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                        type: 'error',
                        });
                    }
                }
                finally{
                    this.$emit('refreshProject')
                }
            },
            async fetchPhaseData(){
                // Initialize local state variables.
                this.phaseDescription = null
                this.phaseDate = null
                this.phaseExists = false
                this.phaseApproved = false
                this.fileName = null
                this.uploadedFileName = null
                this.phaseFiles = null

                try{
                    const params = {phaseNumber: this.phaseNumber}
                    const phase = await fetchProjectPhase(params)  
                    
                    //Populate the values
                    if(phase.data){
                        this.fileName = phase.data.fileName
                        this.phaseDescription = phase.data.phaseDescription
                        this.phaseDate = [phase.data.phaseStart.slice(0,10), phase.data.phaseEnd.slice(0,10)]
                        this.phaseExists = true
                        this.phaseApproved = phase.data.isApproved
                    }
                    else{
                        this.phaseExists = false
                    }
                }
                catch(e){
                    if(e.code === 'ERR_BAD_REQUEST'){
                        ;
                    }
                    else{
                        this.$toast.open({
                            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                            type: 'error',
                        });
                    }   
                }
            },
            async deleteDocument(){
                try{
                    await deleteProjectPhaseDocument({fileName: this.fileName})  
                    this.$toast.open({
                        message: 'Project document deleted.',
                        type: 'info',
                    });
                    this.fetchPhaseData()
                }
                catch(e){
                    if(e.code === 'ERR_BAD_REQUEST'){
                        this.$toast.open({
                            message: e.message,
                            type: 'error',
                        });
                    }
                    else{
                        this.$toast.open({
                            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                            type: 'error',
                        });
                    }   
                }
            },
            async downloadDocument(){
                try{
                    //Fetch the file attributes
                    const fileAttrs = await fetchProjectPhaseDocument({fileName: this.fileName});
                    const fileName = fileAttrs.data.originalName;

                    //Fetch the actual file
                    const response =  await downloadProjectPhaseDocument({fileName: this.fileName});
                    const fileURL = window.URL.createObjectURL(new Blob([response]));
                    const fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', fileName);
                    fileLink.setAttribute('target', '_blank');

                    document.body.appendChild(fileLink);
                    
                    fileLink.click();
                    fileLink.remove();

                    this.$toast.open({
                        message: 'Project document downloaded.',
                        type: 'info',
                    });
                }
                catch(e){
                    console.log(e)
                    if(e.code === 'ERR_BAD_REQUEST'){
                        this.$toast.open({
                            message: e.message,
                            type: 'error',
                        });
                    }
                    else{
                        this.$toast.open({
                            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                            type: 'error',
                        });
                    }   
                }
            }
        },
        computed:{
            phaseButtonDisabled(){
                if(!this.isLeader){
                    return true;
                }

                if(!this.phaseDate){
                    return true
                }

                if(this.isUploading){
                    return true
                }

                if(this.phaseDate.length !=2){
                    return true
                }

                return false
            },
            uploadText(){
                if(this.fileName && this.fileName.length > 0){
                    return 'Upload a new project document'
                }

                return 'Upload a project document'
            }
        },  
        filters: {
            formatDate: function(dt) {
                const date = new Date(dt)
                const day = date.toLocaleString('default', { day: '2-digit' });
                const month = date.toLocaleString('default', { month: 'short' });
                const year = date.toLocaleString('default', { year: 'numeric' });
                return day + '-' + month + '-' + year;
            }
        },
        watch: {
            async phaseFiles(newFile, o){
                this.uploadedFileName = null;
                // Upload here
                if(newFile && newFile.length > 0 && newFile[0].size <= MAX_SIZE){
                    this.isUploading =  true
                    let formData = new FormData();
                    formData.append("files", newFile[0], newFile[0].name);

                    try{
                        const fileUploaded = await saveProjectPhaseDocument(formData)  
                        this.uploadedFileName = fileUploaded.data.fileName
                    }
                    catch(e){
                        if(e.code === 'ERR_BAD_REQUEST'){
                            this.$toast.open({
                                message: e.message,
                                type: 'error',
                            });
                        }
                        else{
                            this.$toast.open({
                                message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                                type: 'error',
                            });
                        }   
                    }
                    finally{
                        this.isUploading =  false
                    }
                }
                else{
                    this.isUploading = false
                    this.uploadedFileName = null
                }
            },
            phaseDate(o,n){
                //console.log(this.phaseDate, o)
                if(this.phaseDate && this.phaseDate.length > 1){
                    const from  = new Date(this.phaseDate[0])
                    const to  = new Date(this.phaseDate[1])
                    if(from.getTime() > to.getTime()){
                        this.phaseDate = [moment(to).format('YYYY-MM-DD'), moment(from).format('YYYY-MM-DD')]
                    }
                }
            },
        }
    }
</script>