<template>
  <div
    class="ma-4"
    style="min-height: 700px"
  >
    <v-expansion-panels v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header expand v-model="panel">
            <span class="title">
              Overall Project Details 
              <v-chip v-if="isLeader" class="ml-2" color='primary'>Can Update</v-chip>
              <v-chip v-else class="ml-2" color='secondary'>Only Team lead can update</v-chip>

              <span v-if='headerExists'>
                <v-chip v-if="isApproved" class="ml-2" color='success'>Project & Phases: Approved!</v-chip>
                <v-chip v-else class="ml-2" color='error'>Under Review</v-chip>
              </span>

            </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="pa-4" justify='space-between'>
            <div>
                <div class="sub-title-1">Project Start & End Dates</div>
                <v-date-picker class="mt-3" 
                    range 
                    no-title 
                    show-adjacent-months
                    :disabled="!isLeader"
                    v-model="projectDate">
                </v-date-picker>
                <div  class="mt-3">
                    <span v-if="projectDate && projectDate.length > 0">From: {{projectDate[0] | formatDate}}</span>
                    <span class="ml-2" v-if="projectDate && projectDate.length > 1">To: {{projectDate[1] | formatDate}}</span>
                </div>
            </div>
            <div style="width: 50%">
                <div class="sub-title-1">Project Name</div>
                <v-text-field :disabled="!isLeader" v-model="projectName" class="pb-4"/>
                <div class="sub-title-1 mb-2">Project Mentor's Feedback</div>
                <v-textarea readonly height="140" v-model="feedback" filled />
            </div>
          </v-row>
          <v-row justify='end'>
            <v-btn color="primary" class='ma-4' @click="updateParams" :disabled='buttonDisabled'>
                Update Project Parameters
            </v-btn>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-for="(item,i) in numberOfPhases" :key="i">
        <v-expansion-panel-header expand v-model="panel">
            <span class="title">
              <span v-if="phaseNames && phaseNames[i]">[Phase {{i+1}}] - {{phaseNames[i].phaseName}}</span>
            </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <phase-details :phaseNumber="panel" :isLeader="isLeader" @refreshProject="fetchParams()"/>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>
  </div>
</template>

<script>

import moment from 'moment';
import {  saveProjectHeader, fetchProjectHeader, getProjectPhaseNames, fetchProjectMentorFeedbackStudent } from '../../api/course';
import PhaseDetails from './PhaseDetails';

export default {
  components: { PhaseDetails },
  mounted() {
    this.fetchParams()
    this.getPhaseNames()
    this.getFeedback()
  },
  filters: {
    formatDate: function(dt) {
      const date = new Date(dt)
      const day = date.toLocaleString('default', { day: '2-digit' });
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.toLocaleString('default', { year: 'numeric' });
      return day + '-' + month + '-' + year;
    }
  },
  data: () => {
    return {
        panel: 0,
        feedback: 'N/A',
        projectDate: null,
        projectName: null,
        numberOfPhases: 4,
        headerExists: false,
        isLeader: false,
        isApproved: false,
        phaseNames: []
    };
  },
  methods:{
    async getPhaseNames(){
      try{
          const phaseNames = await getProjectPhaseNames()   
          
          if(phaseNames && phaseNames.data){
            this.phaseNames = phaseNames.data
            this.numberOfPhases = this.phaseNames.length;
          }
      }
      catch(e){

        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
      }
    },
    async getFeedback(){
      try{
          const feedback = await fetchProjectMentorFeedbackStudent()   
          
          if(feedback && feedback.data && feedback.data.feedback){
            this.feedback = feedback.data.feedback
          }
      }
      catch(e){

        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
      }
    },
    async fetchParams(){
        try{
            const headerParams = await fetchProjectHeader()   
            this.isLeader = headerParams.data.isLeader

            if(headerParams.data.projectHeader){
              this.numberOfPhases = headerParams.data.projectHeader.numberOfPhases
              this.projectDate = [headerParams.data.projectHeader.projectStart.slice(0,10), headerParams.data.projectHeader.projectEnd.slice(0,10)]
              this.projectName = headerParams.data.projectHeader.projectName
              this.isApproved = headerParams.data.projectHeader.isApproved
              this.headerExists = true
            }
            else{
              this.headerExists = false
            }
        }
        catch(e){

          this.headerExists = false
          this.isApproved = false
          this.isLeader = false

          if(e.code === 'ERR_BAD_REQUEST'){
            this.$toast.open({
              message: e.response.data.details || e.response.data.error.message,
              type: 'error',
            });
          }
          else{
            this.$toast.open({
              message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
              type: 'error',
            });
          }
        }
    },
    async updateParams(){
      const params = {
          projectName: this.projectName,
          numberOfPhases: this.numberOfPhases,
          startDate: this.projectDate[0],
          endDate: this.projectDate[1],
      }

      try{
        await saveProjectHeader(params)   
        this.$toast.open({
          message: 'Project parameters updated.',
          type: 'success',
        });
        this.fetchParams()
      }
      catch(e){
        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
      }
    },
  },
  computed:{
    buttonDisabled(){
        if(!this.isLeader){
            return true;
        }

        if(!this.projectDate || !this.projectName || !this.numberOfPhases){
            return true
        }

        if(this.projectDate.length !=2){
            return true
        }

        return false
    }
  },
  watch:{
    projectDate(o,n){
        if(this.projectDate && this.projectDate.length > 1){
            const from  = new Date(this.projectDate[0])
            const to  = new Date(this.projectDate[1])
            if(from.getTime() > to.getTime()){
                this.projectDate = [moment(to).format('YYYY-MM-DD'), moment(from).format('YYYY-MM-DD')]
            }
        }
    },
    phaseDate(o,n){
        if(this.phaseDate && this.phaseDate.length > 1){
            const from  = new Date(this.phaseDate[0])
            const to  = new Date(this.phaseDate[1])
            if(from.getTime() > to.getTime()){
                this.phaseDate = [moment(to).format('YYYY-MM-DD'), moment(from).format('YYYY-MM-DD')]
            }
        }
    },
    async panel(o, n){
      if(this.panel && this.panel > 0){
        ;;
      }
    }
  }
}
</script>
